import { useToggle } from '@ucheba/utils/hooks/useToggle'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { filtering } from '@ucheba/utils/helpers/core/filtering'
import { en2ru } from '@ucheba/utils/helpers/string'
import { isArraysIncludesValuesBoth } from '@ucheba/utils/helpers/array'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import { coreSelectors } from '@ucheba/store/core'
import { IUseBottomSheetHeight, IUseSelectCore } from './types'

/** Основная логика */
export const useSelectCore: IUseSelectCore = (props) => {
  const {
    selectRef,
    items,
    keyValue,
    keyText,
    value,
    suggested,
    multiple,
    autocomplete,
    open,
    onOpen,
    onClose,
    onChange,
    onInput,
    name,
    submitOnClose,
  } = props

  const { setFieldValue, submitForm, values: formValue } = useFormikContext()
  const isTouch = useSelector(coreSelectors.isTouch)
  const [isOpen, toggleOpenSelect] = useToggle(Boolean(open), onOpen)
  const [clickedToggleOpen, setClickedToggleOpen] = useState(false)
  const [isTextFieldFocused, toggleTextFieldFocused] = useToggle(false)
  const [textFieldValue, setTextFieldValue] = useState('')
  const [selectItems, setSelectItems] = useState(items || [])
  const [isReadyClicked, setIsReadyClicked] = useState(false)
  const [selectedValues, setSelectedValues] = useState<Record<string, string>[]>([])
  const [selectedRightNow, setSelectedRightNow] = useState<string[]>([])

  /* Фильтрует выбранные пункты относительно выбранных прямо сейчас */
  const filterSelectedBySelectedRightNow = useCallback(() => {
    const filteredSelected = selectedValues.filter((selected) => {
      return !selectedRightNow.includes(selected[keyValue])
    })

    setSelectedValues(filteredSelected)
  }, [keyValue, selectedRightNow, selectedValues])

  const toggleOpen = useCallback(
    (status?: boolean, readyClicked?: boolean) => {
      if (!status && typeof status === 'boolean') {
        if (!readyClicked && selectedRightNow.length) {
          filterSelectedBySelectedRightNow()
        }

        setSelectedRightNow([])

        if (onClose) onClose()
      }

      setClickedToggleOpen(!status)

      toggleOpenSelect(status)
    },
    [filterSelectedBySelectedRightNow, onClose, selectedRightNow, toggleOpenSelect]
  )

  useEffect(() => {
    /* Если это мобила с множественным выбором и не нажата кнопка "Готово",
     * то не надо выполнять сабмит */
    if (isOpen || !clickedToggleOpen || (isTouch && multiple && !isReadyClicked)) return

    if (submitOnClose) submitForm()

    setIsReadyClicked(false)
  }, [
    clickedToggleOpen,
    isOpen,
    submitForm,
    submitOnClose,
    formValue,
    isTouch,
    multiple,
    isReadyClicked,
  ])

  const getItemById = useCallback(
    (id: string, itemsSource: Record<string, any>[]) => {
      let findItem = {}

      itemsSource.forEach((item) => {
        if (item?.items) {
          item.items.forEach((childItem) => {
            if (String(id) === String(childItem[keyValue])) {
              findItem = childItem
            }
          })
        }

        if (String(id) === String(item[keyValue])) {
          findItem = item
        }
      })

      if (!Object.keys(findItem).length) {
        console.log(`${id} не найден в items: `, itemsSource)
        return null
      }

      const newItem = { ...findItem }

      newItem[keyValue] = String(newItem[keyValue])

      return newItem
    },
    [keyValue]
  )

  /** Превращаем все переданные значения в массив со строковыми id */
  const values = useMemo(() => {
    if (!value || value[0] === '') return []

    const valuesArr = Array.isArray(value) ? value : [value]

    return valuesArr.map((selectedValue) => String(selectedValue))
  }, [value])

  const selectedValuesIds = useMemo(() => {
    return selectedValues.map((selectedValue) => selectedValue[keyValue])
  }, [keyValue, selectedValues])

  /** Обновляем список выбранных пунктов относительно внешних */
  useMemo(() => {
    if (!isArraysIncludesValuesBoth(selectedValuesIds, values)) {
      const fulfilledValues = values
        .map((id) => getItemById(id, items))
        .filter((valueItem) => valueItem)

      setSelectedValues(fulfilledValues)
    }
  }, [values, items]) // selectedValuesIds не добавлять в зависимости

  /** Формирует строку с перечислением выбранных пунктов */
  const selectedText = useMemo(() => {
    const selectedItems = selectedValues.map((selectedValue) => selectedValue[keyText])

    return selectedItems.join(', ')
  }, [keyText, selectedValues])

  /** Проставляем текстовому полю статус `active`, если в нем есть текст или оно в фокусе */
  const isTextFieldActive = useMemo(
    () => Boolean(selectedText || (autocomplete && isTextFieldFocused)),
    [autocomplete, isTextFieldFocused, selectedText]
  )

  /** Проверяет, есть ли в выбранных id пункта */
  const isIdInSelected = useCallback(
    (id) =>
      selectedValues.some((selectedValue) => String(id) === selectedValue[keyValue]),
    [keyValue, selectedValues]
  )

  /** Формируем список пунктов, в котором выбранные пункты будут вверху */
  const sortedItemsBySelected = useMemo(() => {
    const filteredItemsBySelected = items.filter(
      (item) =>
        !selectedValues.some(
          (selectedValue) => String(item[keyValue]) === selectedValue[keyValue]
        )
    )

    return [...selectedValues, ...filteredItemsBySelected]
  }, [items, keyValue, selectedValues])

  /** Когда меню закрыто, добавляем пункты в локальный стейт и сортируем, если есть выбранные */
  useMemo(() => {
    if (!isOpen) {
      const localSelectedItems = selectedValues.length ? sortedItemsBySelected : items

      setSelectItems(localSelectedItems)
    }
  }, [isOpen, items, selectedValues.length, sortedItemsBySelected])

  /** Когда меню открыто и это саджестер, сортируем список по выбранным, если они есть */
  useMemo(() => {
    if (isOpen && suggested) {
      setSelectItems(
        selectedValues.length && !textFieldValue ? sortedItemsBySelected : items
      )
    }
  }, [
    isOpen,
    suggested,
    selectedValues.length,
    textFieldValue,
    sortedItemsBySelected,
    items,
  ])

  /** Фильтруем пункты относительно текста в текстовом поле */
  useMemo(() => {
    if (suggested || !isOpen) return

    let filteredItems = filtering(items, textFieldValue, keyText, 1)

    if (!filteredItems.length) {
      filteredItems = filtering(items, en2ru(textFieldValue), keyText, 1)
    }

    setSelectItems(filteredItems)
  }, [items, keyText, suggested, textFieldValue]) // не добавлять isOpen в зависимости

  /** Вызываем событие изменения у нативного селекта */
  const dispatchEvent = useCallback(
    (eventName) => {
      /** setTimeout нужен для того, чтобы сначала обновились данные о выбранных пунктах,
       *  а затем уже произошел event на нативном селекте */
      setTimeout(() => {
        if (typeof Event === 'undefined') return

        const event = new Event(eventName, { bubbles: true })

        selectRef?.current?.dispatchEvent(event)
      }, 0)
    },
    [selectRef]
  )

  /** При изменении списка выбранных пунктов, если новые пункты не равны тем,
   * что приходят из вне, то вызываем ивент `change` у селекта */
  useMemo(() => {
    if (!isArraysIncludesValuesBoth(selectedValuesIds, values)) dispatchEvent('change')
  }, [dispatchEvent, selectedValuesIds, values])

  /** Вызываем ивент `blur`, когда закрываем меню селекта */
  useMemo(() => {
    if (!isOpen) dispatchEvent('blur')
  }, [dispatchEvent, isOpen])

  /** Выбор пункта при `multiple=true` */
  const toggleItemMultiple = useCallback(
    (id) => {
      const isInSelected = isIdInSelected(id)

      /** Если пункт есть в выбранных, то удаляем его, если нет, то добавляем */
      const newSelectedValues = isInSelected
        ? selectedValues.filter((selectedValue) => selectedValue[keyValue] !== String(id))
        : [...selectedValues, getItemById(id, items) || []]

      if (!isInSelected && isTouch) {
        setSelectedRightNow([...selectedRightNow, String(id)])
      }

      setSelectedValues(newSelectedValues)
    },
    [
      getItemById,
      isIdInSelected,
      isTouch,
      items,
      keyValue,
      selectedRightNow,
      selectedValues,
    ]
  )

  /** Выбор пункта при `multiple=false` */
  const toggleItemSingle = useCallback(
    (id) => {
      if (isIdInSelected(id)) return

      const itemById = getItemById(id, items)

      /** Если пункта нет в выбранных, то добавляем */
      if (itemById) {
        setSelectedValues([itemById])
      }

      // dispatchEvent('change')
      toggleOpen(false)
    },
    [getItemById, isIdInSelected, items, toggleOpen]
  )

  /** Выполняет логику выбора пункта меню */
  const toggleItem = useCallback(
    (id) => {
      if (multiple) {
        toggleItemMultiple(id)
      } else {
        toggleItemSingle(id)
      }
    },
    [multiple, toggleItemMultiple, toggleItemSingle]
  )

  /* Вызывается, когда в мобильном селекте кликнули на кнопку "Готово" */
  const onClickReady = useCallback(() => {
    setIsReadyClicked(true)
    toggleOpen(false, true)
  }, [toggleOpen])

  /** Вызываем `onChange` келбэк функцию, когда нативный селект изменится */
  const onChangeNativeSelect = useCallback(
    (event) => {
      const preparedValue = multiple
        ? selectedValuesIds
        : (selectedValues[0] && selectedValues[0][keyValue]) || []

      /* Устанавливаем в контекст формы */
      if (name) setFieldValue(name, preparedValue)

      if (onChange) onChange(event, preparedValue)
    },
    [keyValue, multiple, name, onChange, selectedValues, selectedValuesIds, setFieldValue]
  )

  /** Вызываем `onInput` келбэк функцию, когда текстовое поле изменится,
   * сохраняем текст из текстового поля и фильтруем список пунктов */
  const onChangeTextField = useCallback(
    (event, text) => {
      setTextFieldValue(text)

      if (onInput) onInput(event, text)
    },
    [onInput]
  )

  /** Если нет дефолтного списка в саджестере, то отправляем пустой запрос */
  useMemo(() => {
    if (suggested && isTextFieldFocused && !textFieldValue) {
      onChangeTextField(null, '')
    }
  }, [isTextFieldFocused, onChangeTextField, suggested, textFieldValue])

  /** Переключаем стейт фокуса на `true`, когда на текстовом поле будет фокус */
  const onFocusTextField = useCallback(
    () => toggleTextFieldFocused(true),
    [toggleTextFieldFocused]
  )

  /** Переключаем стейт фокуса на `false`, когда на текстовом поле не будет фокуса */
  const onBlurTextField = useCallback(
    () => toggleTextFieldFocused(false),
    [toggleTextFieldFocused]
  )

  /** Когда меню закрывается, проставляем статус фокуса false */
  useMemo(() => {
    if (!isOpen) toggleTextFieldFocused(false)
  }, [isOpen]) // toggleTextFieldFocused не добавлять в зависимости

  /** Удаляет выбранные пункты */
  const resetSelected = useCallback(() => {
    if (!selectedValues.length) return

    setSelectedValues([])

    /** TODO: переделать по-человечески
     * submitForm срабатывает до того, как успеет сработать setSelectedValues
     * со сбросом всех выбранных пунктов
     * Поэтому сделал setTimeout
     * */
    setTimeout(() => {
      if (submitOnClose) submitForm()
    }, 10)

    toggleOpen(false)
  }, [selectedValues.length, submitForm, submitOnClose, toggleOpen])

  /** Логика открытия и закрытия меню с пунктами */
  const toggleMenu = useCallback(() => {
    if (autocomplete && isOpen) return

    if (!autocomplete || !isOpen) toggleOpen()
  }, [autocomplete, isOpen, toggleOpen])

  /** Возвращает состояние статуса `active` для пункта меню */
  const getMenuItemActiveStatus = useCallback(
    (id) => Boolean(!multiple && isIdInSelected(id)),
    [isIdInSelected, multiple]
  )

  /** Возвращает состояние статуса `checked` для пункта меню */
  const getMenuItemCheckedStatus = useCallback(
    (id) => Boolean(multiple && isIdInSelected(id)),
    [isIdInSelected, multiple]
  )

  /** Открываем меню, когда текстовое поле в фокусе */
  useMemo(() => {
    if (isTextFieldFocused) {
      toggleMenu()
    }
  }, [isTextFieldFocused]) // toggleMenu не добавлять в зависимости

  /** Когда меню закрывается, проверяем, соответствует ли введенный текст хоть одному из пунктов,
   * если да, то выбираем этот пункт. Так же, очищается введенный текст */
  useMemo(() => {
    if (isOpen) return

    // const itemByText = selectItems.find(
    //   (item) => item[keyText].toLocaleLowerCase() === textFieldValue.toLocaleLowerCase()
    // )
    //
    // if (itemByText) {
    //   toggleItem(String(itemByText[keyValue]))
    // }

    setTextFieldValue('')
  }, [isOpen])

  return {
    selectItems,
    selectedValuesIds,
    selectedText,
    textFieldValue,
    isTextFieldActive,
    isTextFieldFocused,
    isOpen,
    toggleOpen,
    resetSelected,
    toggleMenu,
    getMenuItemActiveStatus,
    getMenuItemCheckedStatus,
    onChangeNativeSelect,
    onChangeTextField,
    onFocusTextField,
    onBlurTextField,
    toggleItem,
    onClickReady,
  }
}

export const useBottomSheetHeight: IUseBottomSheetHeight = (props) => {
  const { autocomplete, isOpen } = props

  const bottomSheetRef = useRef(null as HTMLDivElement | null)
  const [bottomSheetHeight, setBottomSheetHeight] = useState('auto')

  /** Когда есть автокомплит, при открытии меню,
   * фиксируем его высоту, чтобы BottomSheet не скакал при автокомплите */
  useEffect(() => {
    if (autocomplete && isOpen) {
      setTimeout(() => {
        const initialBottomSheetHeight = window.innerHeight - 80

        if (initialBottomSheetHeight) {
          setBottomSheetHeight(`${initialBottomSheetHeight}px`)
        }
      }, 0)
    }
  }, [autocomplete, isOpen])

  return {
    bottomSheetRef,
    bottomSheetHeight,
  }
}
