import { useCallback, useEffect, useLayoutEffect, useMemo } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useDialog } from '@ucheba/ui/components/Dialog/bll'
import {
  locationActions,
  locationSelectors,
  locationThunks,
} from '@ucheba/store/location'
import { userDataActions, userDataSelectors } from '@ucheba/store/userData'
import { useBottomSheetHeight } from '@ucheba/ui/components/Select/bll'
import {
  locationAllRussiaId,
  selectLocation,
} from '@ucheba/utils/middlewares/defineLocationMiddleware'
import { getUpdatedPathByRegionDomain } from '@ucheba/utils/helpers/router'
import { getParsedCookies } from '@ucheba/utils/helpers/core'
import { locationIdsCookie, russiaLocationId } from '@ucheba/utils/constants/core'
import {
  EChooseLocationDataKeys,
  IUseChooseLocationBottomSheetCore,
  IUseChooseLocationCore,
  IUseChooseLocationDialogCore,
  IUseChooseLocationFormCore,
  IUseLocationText,
} from './types'
import { chooseLocationDialogId } from './constants'

// /* Возвращает регионы по массиву из id */
// const getLocationsByIds = (
//   locations: ILocation[],
//   ids: TSelectedLocations
// ): ILocation[] => {
//   return ids.reduce((newLocations, id) => {
//     const location = locations.find((locationItem) => id === String(locationItem.id))
//
//     if (location) {
//       newLocations.push(location)
//     }
//
//     return newLocations
//   }, [] as ILocation[])
// }

export const useChooseLocationCore: IUseChooseLocationCore = (props) => {
  const { closeDialog, open } = props
  const locations = useSelector(locationSelectors.locations)
  const location = useSelector(locationSelectors.location)
  const russiaLocation = useSelector(locationSelectors.allRussiaLocation)
  const selectedLocations = useSelector(locationSelectors.selectedLocations)
  const userData = useSelector(userDataSelectors.entity)
  const dispatch = useDispatch()
  const parsedCookies = useMemo(() => getParsedCookies(), [])
  const locationsIdsByCookie = useMemo(
    () =>
      parsedCookies[locationIdsCookie] && JSON.parse(parsedCookies[locationIdsCookie]),
    [parsedCookies]
  )

  const store = useStore()

  /** Запрашиваем locations, если он не был запрошен */
  useEffect(() => {
    if (!location && locationsIdsByCookie && locationsIdsByCookie.length) {
      dispatch(locationThunks.getById({ data: { id: locationsIdsByCookie[0] } }))
    }
    if (!locations.length && open) {
      dispatch(locationThunks.fetch({ data: { limit: 100, isMain: 1 } }))
    }
  }, [dispatch, locations.length, locationsIdsByCookie, open, location])

  /** Когда в location будет Россия, диспатчим ее на свое место в сторе */
  useEffect(() => {
    if (location?.id === russiaLocationId) {
      dispatch(locationActions.setAllRussiaLocation(location))
    }
  }, [dispatch, location, location?.id])

  /** Мержим выбранные пользователем локации с локациями,
   * которые приходят при запросе в селекте */
  const mergedLocations = useMemo(() => {
    return russiaLocation ? [russiaLocation, ...locations] : locations
  }, [locations, russiaLocation])

  /* Вызываем колбек сабмита и передаем в него массив id выбранных регионов */
  const onSubmitForm = useCallback(async () => {
    selectLocation({ store, locations: selectedLocations || [] })

    closeDialog()

    if (userData.isRedirectOnSelectLocation) {
      window.location.href = await getUpdatedPathByRegionDomain({
        locationId: selectedLocations[0].id,
      })
    }
  }, [closeDialog, selectedLocations, store, userData.isRedirectOnSelectLocation])

  useEffect(() => {
    if (
      locationsIdsByCookie?.length &&
      location &&
      locationsIdsByCookie.some((id) => String(id) === String(location?.id))
    ) {
      dispatch(userDataActions.setSelectedLocation([location]))
    } else if (locationsIdsByCookie?.length && mergedLocations.length) {
      const selectedLocationsByCookie = locationsIdsByCookie
        .map((locationId) => mergedLocations.find((el) => el.id === Number(locationId)))
        .filter((locationItem) => Boolean(locationItem))

      dispatch(userDataActions.setSelectedLocation(selectedLocationsByCookie))
    }
  }, [dispatch, mergedLocations, locationsIdsByCookie, location])

  /** Проставляем только те локации, которые пользователь выбрал ранее */
  const initialValues = useMemo(() => {
    return {
      [EChooseLocationDataKeys.locationIds]: userData.selectedLocations.map((el) =>
        String(el.id)
      ),
    }
  }, [userData.selectedLocations])

  return {
    onSubmitForm,
    initialValues,
    locations: mergedLocations,
  }
}

/** Логика поведения формы выбора локации */
export const useChooseLocationFormCore: IUseChooseLocationFormCore = (props) => {
  const { locations } = props

  const userData = useSelector(userDataSelectors.entity)

  const dispatch = useDispatch()

  const isUserSelectedLocations = useMemo(() => {
    return userData?.selectedLocations?.length
  }, [userData?.selectedLocations?.length])

  /* Устанавливаем в стей формы значения переданных выбранных регионов */
  useEffect(() => {
    if (isUserSelectedLocations) {
      dispatch(locationActions.setSelectedLocations(userData?.selectedLocations))
    }
  }, [dispatch, isUserSelectedLocations, userData?.selectedLocations])

  const onChangeHandler = useCallback(
    (_, locationIds) => {
      if (locationIds.length) {
        /** Находим новую локацию по выбранному айдишнику */
        const newSelectedLocation = locations.find(
          (location) => String(location.id) === locationIds
        )

        dispatch(locationActions.setSelectedLocations([newSelectedLocation]))
      }
    },
    [dispatch, locations]
  )

  return {
    onChangeHandler,
  }
}

/** Логика для показа диалогового окна при условии отсутствия выбранных локаций
 * и отсутствии нотиса с определением локации */
export const useChooseLocationDialogCore: IUseChooseLocationDialogCore = () => {
  const chooseLocationDialog = useDialog(chooseLocationDialogId)
  const userData = useSelector(userDataSelectors.entity)
  const dispatch = useDispatch()

  /** Открывать диалоговое окно */
  useLayoutEffect(() => {
    if (userData?.isShowSelectLocation) {
      chooseLocationDialog.openDialog()
    }
  }, [userData?.isShowSelectLocation]) // не ставить chooseLocationDialog

  /* Получаем новые регионы по имени */
  const suggestingLocations = useCallback(
    (name) => {
      dispatch(locationThunks.fetch({ data: { name, limit: 100, isMain: 1 } }))
    },
    [dispatch]
  )

  const extendedHeaderOpenDialog = useCallback(() => {
    chooseLocationDialog.openDialog()

    dispatch(userDataActions.toggleIsRedirectOnSelectLocation(true))
  }, [dispatch]) // не ставить chooseLocationDialog

  const extendedHeroOpenDialog = useCallback(() => {
    chooseLocationDialog.openDialog()

    dispatch(userDataActions.toggleIsRedirectOnSelectLocation(false))
  }, [dispatch]) // не ставить chooseLocationDialog

  return {
    chooseLocationDialog,
    suggestingLocations,
    extendedHeroOpenDialog,
    extendedHeaderOpenDialog,
  }
}

/** Логика для показа диалогового окна при условии отсутствия выбранных локаций
 * и отсутствии нотиса с определением локации */
export const useChooseLocationBottomSheetCore: IUseChooseLocationBottomSheetCore = () => {
  const userData = useSelector(userDataSelectors.entity)
  const dispatch = useDispatch()

  const isOpenChooseLocation = useMemo(
    () => userData?.isShowSelectLocation,
    [userData?.isShowSelectLocation]
  )

  const chooseLocationBottomSheet = useBottomSheetHeight({
    isOpen: isOpenChooseLocation,
  })

  const toggleOpenChooseLocation = useCallback(
    (value) => {
      dispatch(userDataActions.setIsShowSelectLocation(value))
    },
    [dispatch]
  )

  useLayoutEffect(() => {
    if (isOpenChooseLocation) {
      toggleOpenChooseLocation(true)
    }
  }, [isOpenChooseLocation]) // не ставить chooseLocationDialog

  /* Получаем новые регионы по имени */
  const suggestingLocations = useCallback(
    (name) => {
      dispatch(locationThunks.fetch({ data: { name, isMain: 1, limit: 100 } }))
    },
    [dispatch]
  )

  return {
    chooseLocationBottomSheet,
    suggestingLocations,
    isOpenChooseLocation,
    toggleOpenChooseLocation,
  }
}

/** Текст для вывода выбранных регионов */
export const useLocationText: IUseLocationText = () => {
  const userData = useSelector(userDataSelectors.entity)

  const locationSelected = useMemo(
    () => userData?.selectedLocations[0],
    [userData?.selectedLocations]
  )

  const baseText = useMemo(() => {
    return locationSelected && locationSelected?.id === locationAllRussiaId
      ? 'любом регионе'
      : locationSelected?.namePrepositional || locationSelected?.name || 'любом регионе'
  }, [locationSelected])

  const headerText = useMemo(() => {
    return locationSelected && locationSelected?.id === locationAllRussiaId
      ? 'Россия'
      : locationSelected?.name || 'Россия'
  }, [locationSelected])

  return {
    baseText,
    headerText,
  }
}
